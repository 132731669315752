import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
  } from "./ui/dropdown-menu";
  import { LogOut } from "lucide-react";
  import { Avatar, AvatarFallback } from "./ui/avatar";

export default function Header({email, logout}) {
  return (
    <header className="h-20 bg-slate-900">
      <div className="flex items-center justify-between h-full container max-w-screen-lg">
        <div className="text-2xl font-extrabold text-gray-200 uppercase">
          Faith Deep Dives <span className="font-extralight">AI</span>
        </div>

        <div className="text-gray-200">
          <DropdownMenu>
            <DropdownMenuTrigger className="flex items-center">
              <span className="mr-2">{email}</span>
              <Avatar>
                <AvatarFallback>
                  {(email || "")[0].toUpperCase()}
                </AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={logout}>
                <LogOut className="mr-8 h-4 w-4" />
                <span>Log out</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>


  );
}
