import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  ArrowPathRoundedSquareIcon 
} from '@heroicons/react/24/outline'
import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import backgroundH from "../assets/faith.gif";
const {OpenAI} = require('openai');


function SearchTopic() {
  const [userInput, setUserInput] = useState('');
  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // State to track loading status
  const [hasSubmitted, setHasSubmitted] = useState(false);

  // OpenAI configuration 
  const openai = new OpenAI({ apiKey: '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', dangerouslyAllowBrowser: true  });

  // Handle form submission
  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);  // Start loading
    setHasSubmitted(false);  // Reset submission state

    let result = '';
    try {
        const completion = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [
            { role: "system", content: "You are a non-denominational recommendation engine that takes a topic or phrase from users and returns an exhaustive set of relevant and related scripture, prayers, hymns, books, articles, and sermons." },
            { role: "user", content: userInput }
          ]
        });
        const rawText = completion.choices[0].message.content;
        result = rawText;
      } catch (error) {
        console.error(error);
        result = 'An error occurred while processing your request.';
      }

      // Simulate an API call with a timeout
      setTimeout(() => {
          //const computedResult = `The solution to your problem "${userInput}" is XYZ.`;  // Placeholder for actual result
          setResult(result);
          setIsLoading(false);  // Stop loading
          setHasSubmitted(true);  // Show the result card
      }, 2000);  // Simulate fetching time


      
      setUserInput('');  // Clear the input after submission
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100"  style={{
      backgroundImage: "url(" + backgroundH + ")",
      backgroundSize: "cover",
      height: "100vh",
    }}>
    <h1 className="text-xl text-white font-bold mb-4">Search for a topic or explain what situation you need sciptures for:</h1>
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4 max-w-4xl">
      <label className="block text-gray-700 text-sm font-bold mb-2">
      
        <textarea
          type="text"
          value={userInput}
          onChange={handleInputChange}
          className="form-textarea shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows="4"
        />
      </label><br />
      <button type="submit" className="bg-gray-700 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Search
      </button>
    </form>
    {isLoading && (
                <div className="flex justify-center items-center">
                    <ArrowPathRoundedSquareIcon className="animate-spin h-8 w-8 text-gray-700" />
                    <span className="ml-2 text-gray-500">Processing...</span>
                </div>
            )}
            {hasSubmitted && !isLoading && (
              <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-4xl w-full overflow-auto">
                <p className="text-gray-600 text-lg font-bold">Result:</p><br />
                <div className="text-gray-600 overflow-auto">
                  <Markdown>{result}</Markdown>
                </div>
              </div>
            )}
  </div>
  );
}

export default SearchTopic;
