import React from 'react';

function getYear(){
  return new Date().getFullYear();
}

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center">
      <p>&copy; {getYear()} Faith Deep Dives AI</p>
    </footer>
  );
};

export default Footer;
